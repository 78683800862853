import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

const RedirectComponent = () => {

    const { domainId, asin } = useParams();

    if (domainId === '3')
    {
        window.location.href = `https://amazon.de/d/${asin}/ref=nosim?tag=amznes0f-21`;
        return null;
    }
    else if (domainId === '4')
    {
        window.location.href = `https://amazon.fr/d/${asin}/ref=nosim?tag=amzerrtag-21`;
        return null;
    }
    else if (domainId === '9')
    {
        window.location.href = `https://amazon.es/d/${asin}/ref=nosim?tag=amznes03e-21`;
        return null;
    }
    else
    {
        return <div>Invalid parameters !</div>
    }

};

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/:domainId/:asin" element={<RedirectComponent />} />
                <Route path="/" element={<div>Home Page</div>} />
                <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
        </Router>
    );
};

export default App;
